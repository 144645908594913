const projects = [
    {
        "imageUrl": "./images/undead-kingdom.png", 
        "title": "Undead Kingdom", 
        "description": "Undead Kingdom é um jogo 2D para Android e PC no estilo plataforma que você controla Catherine, uma guerreira de um reino na idade média. De repente, começam a aparecer mortos-vivos pela cidade e seu objetivo é descobrir o que está acontecendo, assim como parar os que aparecerem no seu caminho.", 
        "url": "https://play.google.com/store/apps/details?id=com.felipemsgamedev.UndeadKingdom",
        "type": "game",
        "published": true
    },
    {
        "imageUrl": "./images/calculadora-imc.png",
        "title": "Calculadora de IMC",
        "description": "Aplicativo Android que apresenta o conceito do IMC, realiza os cálculo com base nos valores que você fornece e informa em qual classe de peso a pessoa está.",
        "url": "https://play.google.com/store/apps/details?id=br.com.iascblog.calculadoradeimc",
        "type": "app",
        "published": true
    },
    {
        "imageUrl": "./images/bia.png", 
        "title": "B.I.A.", 
        "description": "B.I.A. é um aplicativo Android para auxiliar na montagem de times de popular fantasy game usado no Brasil.", 
        "url": "https://play.google.com/store/apps/details?id=br.iascblog.bia&hl=pt_BR&gl=US",
        "type": "app",
        "published": true
    },
    {
        "imageUrl": "./images/manual-no-low-poo.png",
        "title": "Manual No/Low Poo - Guia do Cabelo Natural (Retirado da loja)", 
        "description": "No e Low Poo são técnicas de cuidados capilares que buscam melhorar a saúde dos cabelos. Para isso, você precisa saber escolher os produtos certos, observando sempre a composição. Esse era um app Android que desenvolvi em conjunto com minha esposa para tornar essa tarefa mais fácil!",
        "url": "./projetos",
        "type": "app",
        "published": false
    },
    {
        "imageUrl": "./images/algoritools.png",
        "title": "Algoritools",
        "description": "Algoritools é aplicativo Android formado por um conjunto de ferramentas que visa ajudar programadores e desenvolvedores de software no seu dia a dia de trabalho.",
        "url": "https://play.google.com/store/apps/details?id=br.com.algoritools&hl=pt_BR&gl=US",
        "type": "app",
        "published": false
    },
    {
        "imageUrl": "./images/iasc_screen_small.png",
        "title": "Inteligência Artificial sob controle (IAsc)",
        "description": "Alguns anos atrás, após concluir o mestrado, eu buscava me aperfeiçoar para um possível doutorado. Acaba que isso não aconteceu, mas nesse período escrevi alguns textos em um blog voltado para Inteligência Artificial, que foi o tema do meu mestrado.",
        "url": "https://iascblog.wordpress.com",
        "type": "blog"
    }
]

export default projects;