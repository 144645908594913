import './ProjectCard.css';

const ProjectCard = (props) => {
    const {imageUrl, title, description, url} = props
    return (
        <div >
            <li className='project-card'>
                    <div>
                        <img src={imageUrl} alt={title}/>
                    </div>    
                    <div>
                        <a href={url} target='_blank' rel='noreferrer'>{title}</a>
                        <p>{description}</p>
                    </div>
            </li>
        </div>
    )
};

export default ProjectCard;