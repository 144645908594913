import './About.css';

const Sobre = () => {
    return (
        <div className='content'>
            <h2>Sobre</h2>
            <p>
                Olá! Em primeiro lugar, gostaria de agradecer o acesso a essa página pessoal.
                Sei que páginas pessoais não são mais tão comuns quanto já foram, mas é um 
                espaço em que eu me sinto mais à vontade para escrever.
            </p>
            <p>
                Meu nome é Felipe, sou casado e moro na cidade de São Paulo desde sempre. Tenho formação na 
                área de Ciência da Computação e estudo ou trabalho com desenvolvimento de software desde 
                2005. Acessando experiência é possível visualizar as 
                empresas por onde passei nesses últimos anos. 
            </p>
            <p>
                Entre meus interesses estão: programação, games, leitura, política, filmes, séries, música e futebol.
                Na seção de Blog, vou trazer esses assuntos quando for relacionado a algo que estou estudando.
            </p>
            <p>
                Gostou da página? Peço que adicione-a nos seus favoritos! Obrigado!
                Abraços!
            </p>
        </div>
    );
}

export default Sobre;