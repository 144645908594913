import './Menu.css'
import { Link } from 'react-router-dom';

const changeMenuVisibility = () => {
    var menu = document.getElementById('menu-options');
    if (menu.style.display === 'flex') {
        menu.style.display = 'none';
    } else {
        menu.style.display = 'flex';
    }
}

const hideMenu = () => {
    var menu = document.getElementById('menu-options');
    if (menu.style.display === 'flex') {
        menu.style.display = 'none';
    }
}

const Menu = () => {
    return (
        <div className='header'>
            <img id='menu-icon' src='./images/icons/menu-icon.png' alt='menu' onClick={() => changeMenuVisibility()}/>
            <ul id='menu-options' onClick={() => hideMenu()}>
                <li key={'menu_link_1'}><Link to='/blog'>Blog</Link></li>
                <li key={'menu_link_2'}><Link to="/formacao">Formação</Link></li>
                <li key={'menu_link_3'}><Link to="/trabalhos">Experiência</Link></li>
                <li key={'menu_link_4'}><Link to="/publicacoes">Publicações</Link></li>
                <li key={'menu_link_5'}><Link to='/projetos'>Projetos</Link></li>
                <li key={'menu_link_6'}><Link to="/contato">Contato</Link></li>
                <li key={'menu_link_7'}><Link to='/sobre'>Sobre</Link></li>
            </ul>
        </div>
    )
}

export default Menu;