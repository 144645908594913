const academic_works = [
    {
        degree: "Mestrado em Ciência da Computação",
        institution: "Universidade de São Paulo",
        year: 2013,
        title: "Soluções eficientes para processos de decisão markovianos baseadas em alcançabilidade e bissimulações estocásticas",
        url: "./documents/dissertacao.pdf"
    },
    {
        degree: "Bacharelado em Ciência da Computação",
        institution: "Universidade Presbiteriana Mackenzie",
        year: 2009,
        title: "Interatividade em Sistemas de Televisão Digital através de Java TV",
        url: "./documents/TGI.pdf"
    }
];

export default academic_works;