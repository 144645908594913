import './Blog.css';

const Blog = () => {
    return (
        <div className='content'>
            <h2>Blog</h2>
            <div className='blog-text'>
                <h3>Espírito inquieto de desenvolvedor</h3>
                <p>Data: 13 de Novembro de 2022</p>
                <p>
                    De tempos em tempos meu espírito inquieto de desenvolvedor me cobra que eu produza 
                    algo do início ao fim, essa página foi desenvolvida num desses momentos. Recentemente 
                    eu troquei de emprego e estava precisando aprimorar minhas skills 
                    de front-end para o novo desafio, daí vi esse projeto como uma oportunidade.
                </p>
                <p>
                    Essa página foi desenvolvida usando JavaScript, React, CSS e hospedada 
                    no Vercel, que é um serviço de nuvem no estilo plataforma como serviço (PaaS). 
                    Antes disso, eu hospedava alguns projetos no OpenShift, Heroku e AWS. Mas por que 
                    não usei um desses três? Vou explicar a seguir:
                </p>
                <ul>
                    <li>
                        - Quando comecei a trabalhar mais com Java, conheci a OpenShift da RedHat num primeiro 
                        momento. De início gostei, mas eu abandonei o serviço depois que a interface de publicação começou a mudar muito de uma atualização 
                        para outra. Eu precisava sempre reaprender como publicar, até que chegou num ponto 
                        em que eu já não sabia o que era necessário mais. 
                    </li>
                    <li>
                        - Depois de OpenShift, conheci a Heroku, que apesar de ser mais antiga, fui começar 
                        a usar só quando OpenShift começou a me dar dores de cabeça. Eu estava gostando da Heroku, 
                        era fácil de usar e não mudou muito no período em que usei. Mas neste 
                        ano eles avisaram que mudariam suas políticas passando a cobrar pelos serviços.
                        Se eu monetizasse bem meus projetos, isso não seria problema, mas muitos dos projetos 
                        que tenho ou não monetizam bem ou não monetizam nada, por esse motivo fui atrás de outras opções.
                    </li>
                    <li>
                        - E não tem como não falar da AWS. Numa época em que eu estava estudando um pouco de Spring Boot no Java,
                        eu fiz o cadastro na AWS e comecei a experimentar. Estava gostando do serviço, mas o primeiro ano 
                        passou muito rapidamente e logo começaram a cobrar por algo que eu não tinha necessidade real de ter 
                        hospedado. Aí foi o momento que cancelei. Ainda pretendo retomar os estudos de AWS e aí talvez usá-lo como 
                        principal já que é uma ferramenta amplamente usada no mercado e seria um bom investimento. Apesar disso, ainda 
                        não retomei, talvez em breve.
                    </li>
                </ul>    
                <p>
                    Pesquisando um pouco sobre as plataformas na nuvem, vi que Java (que desde 2007 foi minha linguagem 
                    principal) já não tem tantas opções. Por outro lado, hospedagens para 
                    sistemas baseados em JavaScript, Python, Go e outras, estão bem fáceis de se encontrar. 
                </p>
                <p>
                    Nessa direção, vi que a Vercel que é uma dessas plataformas também desenvolveu um popular framework de React, o Next.js. 
                    Isso me deu a impressão que eles sabem o que estão fazendo. Além disso, é free para uso pessoal e não comercial como é o caso 
                    dessa página, e ainda disponibiliza até 100GB de armazenamento. Por esses motivos, resolvi escolher 
                    essa plataforma e você está visitando a página publicada. 
                </p>
                <p>
                    Depois de escolhida, a plataforma não poderia decepcionar dificultando a publicação. E posso dizer que o processo foi bem simples, 
                    basicamente foi necessário criar o cadastro e permitir a Vercel de ter acesso ao repositório no GitHub. Outro ponto 
                    que observei, é que se eu precisar disponibilizar aplicações comerciais, teria de pagar pelo menos 
                    $20 (~R$106,51 na data atual). Nesse caso, valeria uma comparação com a AWS, já que lá o preço 
                    pode variar de acordo com o tráfego.
                </p>
                <p>
                    Para comentários, críticas e/ou elogios, por favor me mande um <a href='mailto:felipemartinsss@gmail.com' target='_blank' rel='noreferrer'>e-mail</a>.
                </p>
            </div>
        </div>
    );
};

export default Blog;