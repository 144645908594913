import './Contacts.css';
import social_networks from '../../data/social_networks';

const Contact = () => {
    return (
        <div className='content'>
            <h2>Contato</h2>
            <p>
                Nessa página estão algumas formas de entrar em contato e perfis em diferentes plataformas:
            </p>
            <ul className='contacts'>    
                {
                    social_networks.map((sn, index) => {
                        return (
                            <li key={index}>
                                <a href={sn.url} target='_blank' rel='noreferrer'>
                                    {sn.name}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Contact;