import ProjectCard from '../ProjectCard/ProjectCard';
import projects from '../../data/projects';
import './Projects.css';

const Projects = () => {
    
    return (
        <div className='content'>
            <h2>Projetos Pessoais</h2>
            <h3>Aplicativos</h3>
            <ul className='projects-list'>
                {
                    projects.filter(project => (project.type === 'app' || project.type === 'game') && project.published)
                            .map((project, index) => 
                                <ProjectCard key={`${project.type}_${index}`} imageUrl={project.imageUrl}
                                            title={project.title}
                                            description={project.description} 
                                            url={project.url} />
                    )
                }
                
            </ul>
            <h3>Textos</h3>
            <ul className='projects-list'>
                {
                    projects.filter(project => project.type === 'blog')
                            .map((project, index) => 
                                <ProjectCard key={`${project.type}_${index}`} imageUrl={project.imageUrl}
                                            title={project.title}
                                            description={project.description} 
                                            url={project.url} />
                    )
                }
                
            </ul>
        </div>
    );
}

export default Projects;