const publications = [
    {
        title: 'Reachability-based model reduction for Markov decision process',
        publisher: 'Journal of Brazilian Computer Society (JBCS)',
        year: '2015',
        url: 'https://link.springer.com/article/10.1186/s13173-015-0024-1'
    },
    {
        title: 'Stochastic Bisimulation for MDPs Using Reachability Analysis',
        publisher: 'Brazilian Conference on Intelligent Systems (BRACIS)',
        year: '2013',
        url: 'https://ieeexplore.ieee.org/document/6726451'
    }
];

export default publications;