import './EducationCard.css';

const EducationCard = (props) => {
    const { id, degree, institution, title, url, year } = props;
    return (
        <li className="education-card">
            <div>
                <label htmlFor={`degree_${id}`}>Curso: </label>
                <span id={`degree_${id}`}>{degree}</span>
            </div>
            <div>
                <label htmlFor={`institution_${id}`}>Instituição: </label>
                <span id={`institution_${id}`}>{institution}</span>
            </div>
            <div>
                <label htmlFor={`title_${id}`}>Trabalho de Conclusão do Curso: </label>
                <span id={`title_${id}`}>
                    <a href={url} target='_blank' rel='noreferrer'>{title}</a>
                </span>
            </div>
            <div>
                <label htmlFor={`year_${id}`}>Ano de Conclusão: </label>
                <span id={`year_${id}`}>
                    {year}
                </span>
            </div>
        </li>
    );
};

export default EducationCard;