import './Jobs.css';

const Jobs = () => {
    return (
        <div className="content">
            <h2>Experiência</h2>
            <ul className='works'>
                <li>OLX (atual)</li>
                <li>TOTVS / Dimensa</li>
                <li>Accenture</li>
                <li>Liquigás</li>
                <li>Everis</li>
            </ul>
        </div>
    );
}

export default Jobs;