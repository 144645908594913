import './Publications.css';
import publications from '../../data/publications';

const Publications = () => {
    return (
        <div className='content'>
            <h2>Publicações</h2>
            <ul className='publications'>
            {
                publications.map((publication, index) => 
                    <li key={index}>
                        <div>
                            <div>
                                <label htmlFor={`pub_title_${index}`}>Título: </label>
                                <span id={`pub_title_${index}`}>
                                    <a href={publication.url} target='_blank' rel='noreferrer'>
                                        {publication.title}
                                    </a>
                                </span>
                            </div>
                            <div>
                                <label htmlFor={`pub_publisher_${index}`}>Publisher: </label>
                                <span id={`pub_publisher_${index}`}>{publication.publisher}</span>
                            </div>
                            <div>
                                <label htmlFor={`pub_year_${index}`}>Ano de Publicação: </label>
                                <span id={`pub_year_${index}`}>{publication.year}</span>
                            </div>
                        </div>
                    </li>
                )
            }
            </ul>
        </div>
    )
}

export default Publications;