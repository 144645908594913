import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Projects from '../../components/Projects/Projects';
import About from '../../components/About/About';
import Blog from '../../components/Blog/Blog';
import Contacts from '../../components/Contacts/Contacts';
import Jobs from '../../components/Jobs/Jobs';
import Education from '../../components/Education/Education';
import Publications from '../../components/Publications/Publications';

const Content = () => {
    return (
        <Routes>
            <Route path='/' element={<Blog />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/sobre' element={<About />} />
            <Route path='/projetos' element={<Projects />} />
            <Route path='/publicacoes' element={<Publications />} />
            <Route path='/contato' element={<Contacts />} />
            <Route path='/formacao' element={<Education />} />
            <Route path='/trabalhos' element={<Jobs />} />
        </Routes>
    );
}

export default Content;