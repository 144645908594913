const social_networks = [
    {
        name: 'Email',
        url: 'mailto:felipemartinsss@gmail.com'
    },
    {
        name: 'Facebook',
        url: 'https://web.facebook.com/felipemartinsssbr'
    },
    {
        name: 'GitHub',
        url: 'https://github.com/felipemartinsss'
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/felipemartinsss'
    },
    {
        name: 'Itch.io',
        url: 'https://felipemsgamedev.itch.io/'
    },
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/felipemartinsss/'
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/felipemsantos87'
    },
    {
        name: 'Viva O Linux',
        url: 'https://www.vivaolinux.com.br/~felipemartinsss'
    },
    {
        name: 'WordPress',
        url: 'https://iascblog.wordpress.com/'
    },
    {
        name: 'YouTube',
        url: 'https://www.youtube.com/@felipemartinsss'
    }
];

export default social_networks;