import EducationCard from '../EducationCard/EducationCard';
import academic_works from "../../data/academic_works";
import './Education.css';

const Education = () => {
    return (
        <div className='content'>
            <h2>Formação Acadêmica</h2>
            <ul className='education-list'>
            {
                academic_works.map((academic_work, id) => 
                    <EducationCard key={id} id={id} {...academic_work} />)
            }
            </ul>
        </div>
    );
}

export default Education;
