import './App.css';
import Menu from './layout/Menu/Menu';
import Content from './layout/Content/Content';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <>
      <BrowserRouter>
        <Menu />
        <Content />
      </BrowserRouter>
    </>
  );
}

export default App;
